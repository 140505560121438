import React, {useEffect} from 'react'
import './App.css'
function Kenya() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
  return (
    <div className="dialog-off-canvas-main-canvas" data-off-canvas-main-canvas="">
    <div className="views-element-container">
      <div className="js-view-dom-id-4290717ce4d27fa828a05dffbbd6823ccd1f4d109c6a3bb46146b7f27e72c4e5"></div>
    </div>
    <header className="usa-header usa-header--extended">
      <div className="usa-navbar">
        <div
          id="block-votegov-sitebranding"
          className="block system-branding-block"
        >
          <div className="usa-logo">
            <a href="https://vote.gov/" rel="home" title="Vote.gov">
              <div id="vote-logo" aria-label="Vote.gov - Home">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="vote-logo"
                  role="img"
                  width={200}
                  height={70}
                  viewBox="0 0 200 70"
                  fill="none"
                >
                  <path
                    fill="#1F4280"
                    d="M136.5,45L136.5,45c1.7,0,3,1.3,3,3c0,1.6-1.3,3-3,3h0c-1.6,0-3-1.3-3-3C133.6,46.3,134.9,45,136.5,45z"
                  />
                  <path
                    fill="#1F4280"
                    d="M95.8,30.4h3.6v-6.3h6.1v6.3h4.4v4.2h-4.4v9c0,1.7,0.6,2.7,3,2.7c0.4,0,1,0,1.4-0.1v4.2
                           c-0.9,0.2-2.3,0.4-3.7,0.4c-4.7,0-6.8-2.2-6.8-6.1V34.7h-3.6V30.4L95.8,30.4z"
                  />
                  <path
                    fill="#1F4280"
                    d="M73.7,30.4l-7.5,20.1h-6.5l-7.5-20.1h6.5L63,44.3h0.1l4.4-13.8H73.7L73.7,30.4z"
                  />
                  <path
                    fill="#1F4280"
                    d="M73.9,40.5c0-6,3.7-10.5,10.5-10.5C91.3,30,95,34.5,95,40.5c0,6-3.7,10.5-10.5,10.5
                           C77.6,50.9,73.9,46.5,73.9,40.5L73.9,40.5z M80.2,40.5c0,3.3,1.4,5.9,4.2,5.9c2.8,0,4.3-2.5,4.3-5.9c0-3.3-1.4-5.9-4.2-5.9
                           C81.7,34.6,80.2,37.1,80.2,40.5z"
                  />
                  <path
                    fill="#1F4280"
                    d="M130.5,47.4c-1.8,2.1-4.3,3.5-8.5,3.5c-7.1,0-10.7-4.2-10.7-10.4c0-6.2,3.5-10.5,10.2-10.5s9.7,4.3,9.7,9.9
                           c0,0.8,0,1.7-0.2,2.4h-13.5c0.3,2.6,1.6,4.2,4.7,4.2c1.7,0,3.3-0.7,4.5-2.3L130.5,47.4L130.5,47.4z M125.2,38.5
                           c-0.2-2.5-1.2-4.1-3.7-4.1c-2.5,0-3.7,1.6-3.9,4.1H125.2z"
                  />
                  <path
                    fill="#1F4280"
                    d="M159.3,48.7c0,6.2-3.9,10.1-9.4,10.1c-3.1,0-5.4-0.9-7.1-2l1.1-1.9c1.7,1.1,3.6,1.9,6.1,1.9c4,0,7-2.9,7-8.2
                           v-2.7H157c-1.5,2.7-4,4.4-7.2,4.4c-5.4,0-8.6-4.5-8.6-10S144.5,30,150,30c3.4,0,5.7,1.8,7.1,4.2h0.1l0.1-3.8h2.1V48.7L159.3,48.7z
                           M157.1,40.2c0-4.6-2.5-8.2-6.9-8.2s-6.9,3.7-6.9,8.2c0,4.5,2.3,8.1,6.9,8.1S157.1,44.6,157.1,40.2z"
                  />
                  <path
                    fill="#1F4280"
                    d="M162.2,40.4c0-5.7,3.5-10.4,9.6-10.4c6,0,9.5,4.7,9.5,10.4c0,5.7-3.5,10.4-9.6,10.4S162.2,46.2,162.2,40.4z
                           M164.5,40.5c0,4.6,2.6,8.5,7.3,8.5c4.7,0,7.3-3.8,7.3-8.5s-2.6-8.5-7.3-8.5C167.1,32,164.5,35.8,164.5,40.5z"
                  />
                  <path
                    fill="#1F4280"
                    d="M200.5,30.4l-8.1,20h-2.3l-8.1-20h2.4l6.9,17.4h0.1l6.8-17.4H200.5L200.5,30.4z"
                  />
                  <path
                    fill="#1F4280"
                    d="M44.6,58.8H0.5V18.4h28.1l-1.4,3H3.5v34.4h38V30.5h3V58.8z"
                  />
                  <path
                    fill="#DE3245"
                    d="M44.9,12.2H34.7l-1.4,3h10.2L44.9,12.2z M27.7,27.4h10.2l1.4-3H29.1L27.7,27.4z M30.5,21.4h10.2l1.4-3H31.9
                           L30.5,21.4z M24.9,33.5h10.2l1.4-3H26.3L24.9,33.5z M22.2,39.6h10.2l1.4-3H23.6L22.2,39.6z M19.3,45.6h10.2l1.4-3H20.7L19.3,45.6z
                           M16.5,51.7h10.2l1.4-3H17.9L16.5,51.7z"
                  />
                  <path
                    fill="#1F4280"
                    d="M16.9,30.5H6.7l8.2,17.6L19.9,37L16.9,30.5z"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>

    </header>
    <div data-drupal-messages-fallback="" className="hidden" />
    <main
      role="main"
      id="main-content"
      className="grid-container content-container"
    >
      <div className="page-masthead page-masthead--image-offset">
        <h1 className="page-title" data-test="mainHeader">
          Register to vote in Kenya
        </h1>
      </div>
      <div className="vote-page-content vote-page-content--image-offset">
        <div id="block-votegov-content" className="block system-main-block">
          <div className="node--state-territory node--view-mode-full vote-state-territory-content">
            <div className="field field--name-field-media field--type-entity-reference field--label-hidden">
              <div className="field__item">
                <figure className="vote-media vote-media--image vote-media--full">
                  <img
                    src="/assets/Nairobi.jpg"
                    width={960}
                    height={540}
                    alt="Kenya Rocky Mountain lake and peaks in the fall"
                    loading="lazy"
                  />
                </figure>
              </div>
            </div>
            <div className="vote-narrow-container">
              <h2>How to register and update your registration</h2>
              <p>
                <a
                  href="https://www.sos.state.co.us/voter/pages/pub/olvr/verifyNewVoter.xhtml"
                  data-state-ext="online-registration"
                >
                  Start or update your registration online
                </a>{" "}
                on Kenya’s election website.
              </p>
              <p>
                You can also{" "}
                <a
                  href="https://www.Kenyasos.gov/voter/pages/pub/home.xhtml"
                  data-state-ext="offline-registration"
                >
                  register to vote by mail or in person
                </a>{" "}
                on Kenya’s election website.
              </p>
              <h2>Voter registration deadlines</h2>
              <p>
                Voter registration deadlines are for the Tuesday, November 5,
                2024 election.{" "}
                <a href="https://www.eac.gov/voters/register-and-vote-in-your-state">
                  Find state and local election dates.
                </a>
              </p>
              <ul data-test="registrationInfo">
                <li>
                  <strong>Online registration deadline:</strong>{" "}
                  <time dateTime="2024-10-28T08:00:00Z">
                    Monday, October 28, 2024
                  </time>
                  <span className="time nonvfont"> </span>
                </li>
                <li>
                  <strong>Register by mail deadline:</strong> Must be postmarked
                  by{" "}
                  <time dateTime="2024-10-28T12:00:00Z">
                    Monday, October 28, 2024
                  </time>
                </li>
                <li>
                  <strong>In person registration deadline:</strong>{" "}
                  <time dateTime="2024-11-05T07:00:00Z">
                    Tuesday, November 5, 2024
                  </time>
                  <span className="time nonvfont" />
                </li>
              </ul>
              <h2>How to check your voter registration</h2>
              <p>
                You can{" "}
                <a
                  href="https://www.sos.state.co.us/voter/pages/pub/olvr/findVoterReg.xhtml"
                  data-state-ext="confirm-registration"
                >
                  confirm your voter registration status
                </a>{" "}
                on Kenya’s election website.
              </p>
              </div>
          </div>
        </div>
      </div>
    </main>
    <footer className="usa-footer">
      <div className="vote-usagov-partner">
        <nav
          className="vote-usagov-partner__links"
          aria-label="External election resources"
        >
          <div>
            <h2 className="vote-heading--red-underline">
              Learn more about U.S. elections
            </h2>
            <ul>
              <li>
                <p>
                  <a href="https://www.usa.gov/state-local-elections">
                    <strong>State and local elections</strong>
                  </a>
                </p>
                <p>
                  Learn about elections for governor, mayor, and local
                  offices.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.usa.gov/presidential-election-process">
                    <strong>Presidential election process</strong>
                  </a>
                </p>
                <p>
                  Learn about the timeline in a presidential election
                  cycle.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.usa.gov/primaries-caucuses">
                    <strong>Primaries and caucuses</strong>
                  </a>
                </p>
                <p>
                  Learn how political parties choose candidates for the
                  presidential election.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.usa.gov/midterm-elections">
                    <strong>Congressional and midterm elections</strong>
                  </a>
                </p>
                <p>
                  Learn about elections for the U.S. House of Representatives
                  and the U.S. Senate.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.usa.gov/electoral-college">
                    <strong>Electoral college</strong>
                  </a>
                  &nbsp;
                </p>
                <p>
                  Learn how the president and vice president are selected.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.usa.gov/election-results">
                    <strong>Election results</strong>
                  </a>
                  &nbsp;
                </p>
                <p>
                  Find official results from national, state, and local
                  elections after Election Day.&nbsp;
                </p>
              </li>
            </ul>
          </div>
        </nav>
        <div className="vote-usagov-partner__contact-container">
          <div className="vote-usagov-partner__contact">
            <div className="vote-usagov-partner__logo">
              <a href="https://www.usa.gov/" extlinkjs-ignore="">
                <img
                  src="https://vote.gov/themes/custom/votegov/img/usagov-logo.svg"
                  alt="USA.gov logo"
                  width={48}
                  height={48}
                />
              </a>
              <div className="vote-usagov-partner__disclaimer">
                <p>
                  Visit our partner site to find more information about voting
                  in the United States.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="vote-eac-partner vote-block-padding-y-narrow"
        data-test="footerContact"
      >
        <div className="vote-eac-partner__container">
          <div className="vote-eac-partner__logo">
            <div className="vote-eac-partner__text">
              <p>Vote.gov is in partnership with</p>
            </div>
            <div className="vote-eac-partner__logo-img">
              <a
                extlinkjs-ignore=""
                href="https://www.eac.gov/"
                target="_blank"
                rel="noreferrer"
                className="vote-eac-partner__logo-link"
              >
                <img
                  width={64}
                  height={64}
                  src="https://vote.gov/themes/custom/votegov/img/eac-logo.webp"
                  alt="U.S. Election Assistance Commission logo which indicates a partnership with vote.gov"
                />
                <span>U.S. ELECTION ASSISTANCE COMMISSION</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="usa-identifier vote-block-padding-y-narrow">
        <section className="usa-identifier__section usa-identifier__section--masthead padding-top-0">
          <div className="usa-identifier__container">
            <div className="usa-identifier__logos">
              <a href="https://www.gsa.gov/" className="usa-identifier__logo">
                <img
                  className="usa-identifier__logo-img"
                  src="https://vote.gov/themes/custom/votegov/img/gsa-logo.svg"
                  alt="GSA logo"
                />
              </a>
            </div>
            <section className="usa-identifier__identity">
              <p className="usa-identifier__identity-domain">Vote.gov</p>
              <p className="usa-identifier__identity-disclaimer">
                An official website of the{" "}
                <a href="https://www.gsa.gov/">
                  General Services Administration
                </a>
                .
              </p>
            </section>
          </div>
        </section>
        <section className="usa-identifier__section usa-identifier__section--usagov">
          <div className="usa-identifier__container">
            <p>
              Looking for U.S. government information and services?{" "}
              <a href="https://www.usa.gov/" className="usa-link">
                Visit USA.gov
              </a>
              .
            </p>
          </div>
        </section>
        <a
          className="usa-button vote-touchpoints--button"
          target="_blank"
          rel="noreferrer"
          title="External link opens in new window"
          href="https://touchpoints.app.cloud.gov/touchpoints/c169d3b2/submit"
          extlinkjs-ignore=""
        >
          How can we improve vote.gov?
        </a>
      </div>
    </footer>
  </div>
  )
}

export default Kenya
