import React, {useEffect} from 'react'
import './CommunityDivision.css'

import Imag1 from "../../images/rosa1.jpeg"
import Imag2 from "../../images/rosa2.jpeg"
import Imag3 from "../../images/rosa5.jpg"
import Imag4 from "../../images/rosa4.jpeg"

import Exhibit1 from "../../images/ros1.jpeg"
import Exhibit2 from "../../images/ros2.jpeg"
import Exhibit3 from "../../images/ros3.jpeg"
import Exhibit4 from "../../images/ros4.jpg"




import { Button } from '@mui/material'

function CommunityDivision() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageCommunityDivision'>


      <div>
        <div>
            <p>
            We believe that access to nutritious meals is essential for the well-being of individuals and families. Our dedicated team works tirelessly to ensure that everyone in our community has access to the food they need to thrive.
            </p>
            <p>
                We understand the importance of making healthy choices when it comes to food. That's why Rosa's Food Pantry is committed to empowering our community with the knowledge and resources they need to make informed decisions about their diet. We offer cooking classes, recipe ideas, and nutritional education to help you and your family lead a healthier lifestyle.
            </p>
            <p>
                If your family is in need of assistance, we are here to help. Please fill out our application form from the pantry or call us via our email or contact number. Our team will review your application and schedule future appointments for you and your loved ones accordingly. We want to ensure that everyone who requires our support receives it with dignity and respect.
            </p>

            <div className='locTime'>
                <div>
                    <h4 style={{color: '#f5ffff'}}>Location</h4>
                    <p>89 Commerce Street Hawkinsville</p>
                    <p>Georgia, USA</p>
                </div>

                <div>
                    <h4 style={{color: '#f5ffff'}}>Hours of Operation</h4>
                    <p>Thursdays and Friday</p>
                    <p>10:00 AM - 4:00 PM EST</p>
                </div>

                <div>
                    <h4 style={{color: '#f5ffff'}}>Contact</h4>
                    <p>+1 478-285-4685</p>
                    <p>Email: sharon@thenewberryfoundation.org</p>
                </div>
            </div>

            <Button onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=MFFBRMLYNY4WG', '_blank');
          }}>Donate</Button>
        </div>

        <div className='huh2Parent'>
            <div className='huh2'>
                <img src={Imag1} alt="" />
                <img src={Imag2} alt="" />
                <img src={Imag3} alt="" />
                <img src={Imag4} alt="" />
            </div>
        </div>
        </div>

       <div className='communityActivities'>
          <div className='communityActivitiesCover'>
            <div className='communityActivitiesCoverCardHolder'>
                <div>
                    <h2>Activities</h2>
                </div>
                <div>
                    <img src={Exhibit1} alt="" />
                </div>
                <div>
                    <h2>Food Distribution Events</h2>

                    <p>We hold food distribution events where individuals and families in need can access essential food items. These events are held twice a week or as communicated depending on the pantry's resources.</p>
                </div>
                <div>
                    <img src={Exhibit2} alt="" />
                </div>
                <div>
                    <h2>Food Drives and Donation Collection</h2>

                    <p>
                        To sustain our operations, we occasionally conduct food drives and actively seek donations from local businesses, schools, and community members. Volunteers are welcome to coordinate these efforts and we encourage others to contribute non-perishable food items, hygiene products, and other essential items.
                    </p>
                </div>
                <div>
                    <img src={Exhibit3} alt="" />
                </div>
                <div>
                    <h2>Community Outreach and Education</h2>

                    <p>
                        We engage in community outreach and education programs to raise awareness about hunger issues and connect with those in need. This could involve collaborating with local schools, community centers, and other organizations to provide information about available resources and assistance programs.
                    </p>
                </div>
                <div>
                    <img src={Exhibit4} alt="" />
                </div>
                <div>
                    <h2>Volunteer Engagement</h2>

                    <p>
                        We have a lean staff and as such rely on volunteers for various tasks, such as sorting and organizing donated items, assisting with distribution events, and managing administrative responsibilities.
                    </p>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default CommunityDivision
