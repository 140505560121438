import React, {useEffect} from 'react'
import './ResearchDivision.css'

import Imag1 from "../../images/annie1.jpg"
import Imag2 from "../../images/annie2.jpg"
import Imag3 from "../../images/annie3.jpg"
import Imag4 from "../../images/annie/annie5.jpeg"

import Exhibit1 from "../../images/annie/annie1.jpeg"
import Exhibit2 from "../../images/annie/annie2.jpeg"
import Exhibit3 from "../../images/annie/annie3.jpeg"
import Exhibit4 from "../../images/annie/annie4.jpeg"

// 

import { Button } from '@mui/material'

function ResearchDivision() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []); 

  return (
    <div className='landingPageResearchDivision'>
      <div>
        <div>
            <h1>Annie Lee's Porch</h1>
            <p>Nested in the heart of Hawkinsville Georgia lies a hidden gem of Southern Heritage and Culture.</p>
            <p>Annie Lee's Porch Southern Heritage Center is a tribute to the legacy and contributions of African Americans in particular.</p>
            <p>The Center is dedicated to preserving and showcasing the rich history and traditions of the African American community. With a collection of historical artifacts, educational exhibits, and rare photographs, the Center offers visitors a unique opportunity to learn about the Black experience and to gain a deeper understanding and appreciation of Southern and  African American Culture.</p>

            <div className='locTime'>
                <div>
                    <h4 style={{color: '#f5ffff'}}>Location</h4>
                    <p>89 Commerce Street Hawkinsville</p>
                    <p>Georgia, USA</p>
                </div>

                <div>
                    <h4 style={{color: '#f5ffff'}}>Hours of Operation</h4>
                    <p>Thursdays and Friday</p>
                    <p>10:00 AM - 5:00 PM EST</p>
                </div>

                <div>
                    <h4 style={{color: '#f5ffff'}}>Contact</h4>
                    <p>+1 478 982 333</p>
                    <p>Email: info@thenewberryinstitutes.org</p>
                </div>

                <div>
                    <h4 style={{color: '#f5ffff'}}>Group Tours / Admission</h4>
                    <p>We offer discounts for seniors, students and groups.</p>
                    <p>We encourage visitors to book in advance to ensure availability.</p>
                </div>
            </div>

            <Button onClick={()=>{
            window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
          }}>Donate</Button>
        </div>

        <div className='huh2Parent'>
            <div className='huh2'>
                <img src={Imag1} alt="" />
                <img src={Imag2} alt="" />
                <img src={Imag3} alt="" />
                <img src={Imag4} alt="" />
            </div>
        </div>
        </div>

       <div className='researchActivities'>
          <div className='researchActivitiesCover'>
            <div className='researchActivitiesCoverCardHolder'>
                <div>
                    <h2>Activities</h2>
                </div>
                <div>
                    <img src={Exhibit1} alt="" />
                </div>
                <div>
                    <h2>Exhibitions and Displays</h2>

                    <p>The Center periodically hosts rotating exhibitions and displays that highlight various aspects of the local culture and heritage. This includes artifacts, artwork, photographs, and interactive exhibits that provide visitors with a deeper understanding of the community's history in southern Georgia.</p>
                </div>
                <div>
                    <img src={Exhibit2} alt="" />
                </div>
                <div>
                    <h2>Programs and Workshops</h2>

                    <p>
                        We frequently organize educational programs and workshops. These can cover a range of topics from the historical events in Hawkinsville and throughout Georgia. The aim is to engage visitors of all ages and backgrounds in learning about and appreciating the cultural heritage.
                    </p>
                </div>
                <div>
                    <img src={Exhibit3} alt="" />
                </div>
                <div>
                    <h2>Performances and Events</h2>

                    <p>
                        Live musical performances and cultural festivals. These activities include traditional music and dance performances, storytelling sessions, and cultural celebrations. Hosting such events helps bring the community together and fosters a sense of pride in our diverse cultural identity.
                    </p>
                </div>
                <div>
                    <img src={Exhibit4} alt="" />
                </div>
                <div>
                    <h2>Archives and Research Facilities</h2>

                    <p>
                        We maintain archives and research facilities. Researchers, historians, and interested individuals can access these resources and learn to trace their genealogy, study and document their personal and community's history. This contributes to the overall preservation and dissemination of cultural knowledge.
                    </p>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ResearchDivision
